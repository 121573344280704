import { googleMapsApiKey, googleMapsDefaultTheming } from '../../config/google-maps';
import { helpers } from 'gmap-vue';

const { googleMapsApiInitializer } = helpers;

export default {
    props: {
        segment: String,
        countries: String,
        initialLatitude: Number,
        initialLongitude: Number,
        initialZoom: Number,
        markerLight: String,
        markerDark: String,
    },
    data() {
        return {
            hasConsent: false,
            isLoading: false,
            isCurrentLocation: false,
            locationCoords: { lat: null, lng: null },
            locationDistance: null,
            dealers: [],
            dealer: null,
            markers: [],
            mapCenter: { lat: 51.163520, lng: 4.777800 },
            mapZoom: 13,
            mapOptions: {
                disableDefaultUI: false,
                minZoom: 8,
                maxZoom: 15,
                styles: googleMapsDefaultTheming
            },
            infoWindowPos: null,
            infoWindowOpen: false,
            infoWindowOptions: {
                pixelOffset: {
                    width: 0,
                    height: -45
                },
                maxWidth: 390
            },
            dateOptions: {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }
        }
    },
    computed: {
        geolocationSupported() {
            return 'geolocation' in navigator;
        },
        countryRestrictions() {
            return JSON.parse(`{ "country": ${this.countries} }`);
        },
        locationDistanceOptions: function() {
            switch (this.segment) {
                case 'pro':
                    return [25, 50, 75];
                default:
                    return [5, 10, 20];
            }
        },
        locale() {
            return this.api.getCookie('sw-iso-639-3166').split('_')[1];
        }
    },
    created() {
        // For DE website, await the event
        // Example: document.dispatchEvent(new Event('mapsConsentGiven'));
        // if (this.locale == 'de-DE') {
        //     document.addEventListener('mapsConsentGiven', () => this.consentGiven(), false);
        // } else {
            this.consentGiven();
        // }
    },
    methods: {
        consentGiven() {
            // TODO: make sure the initial markers are loaded after consent has given
            googleMapsApiInitializer({
                key: googleMapsApiKey,
                libraries: 'places'
            }, false);

            this.hasConsent = true;
        },
        initMap() {
            if (this.initialLatitude && this.initialLongitude) {
                this.mapCenter.lat = this.initialLatitude;
                this.mapCenter.lng = this.initialLongitude;

                this.locationCoords = this.mapCenter;
            }

            if (this.initialZoom) {
                this.mapZoom = this.initialZoom;
            }
        },

        setLocationCoords(lat, lng) {
            this.locationCoords.lat = lat;
            this.locationCoords.lng = lng;

            this.doSearch();
        },

        getLocationCoords(place) {
            const coords = place.geometry.location;

            this.setLocationCoords(coords.lat(), coords.lng());
            this.isCurrentLocation = false;
        },

        getCurrentLocationCoords() {
            navigator.geolocation.getCurrentPosition((position) => {
                const coords = position.coords;

                this.setLocationCoords(coords.latitude, coords.longitude);
                this.isCurrentLocation = true;
                this.$refs.input.value = '';
            });
        },

        async getAddressStringCoordinates(address) {
            const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${googleMapsApiKey}`;

            try {
                const response = await fetch(endpoint);

                if (response.ok) {
                    const data = await response.json();

                    if (data.results && data.results.length > 0) {
                        const location = data.results[0].geometry.location;

                        this.setLocationCoords(location.lat, location.lng);
                    } else {
                        console.error('Error fetching geocoding data:', 'No results found for provided criteria.');
                    }
                } else {
                    console.error('Geocoding API error:', response.status);
                }
            } catch (error) {
                console.error('Error fetching geocoding data:', error);
            }
        },

        updateMarkers(fitBounds = true) {
            if (!this.hasConsent) {
                return
            }

            const bounds = new google.maps.LatLngBounds();

            this.markers = [];
            this.dealers.forEach((dealer) => {
                const coords = { lat: dealer.latitude, lng: dealer.longitude };
                const icon = (dealer.premium || dealer.propremium) ? this.markerDark : this.markerLight;

                this.markers.push({
                    coords: coords,
                    icon: icon,
                    dealer: dealer
                });

                bounds.extend(coords);
            });

            if (fitBounds) {
                this.fitBoundsMarkersHandler(bounds);
            }
        },

        fitBoundsMarkersHandler(bounds) {
            this.$refs.map.fitBounds(bounds);
        },

        showMarkerDetails(marker, toggle = true) {
            this.infoWindowPos = marker.coords;

            if (this.dealer && this.dealer.id == marker.dealer.id) {
                if (toggle) {
                    this.infoWindowOpen = !this.infoWindowOpen;
                } else {
                    this.infoWindowOpen = true;
                }
            } else {
                this.infoWindowOpen = true;
                this.dealer = marker.dealer;
            }
        },

        showDealerDetails(dealer, toggle = true) {
            const dealerMarker = this.markers.find((marker) => marker.dealer.id === dealer.id);

            if (dealerMarker) {
                this.showMarkerDetails(dealerMarker, toggle);
            }
        },

        isNaturapy(dealer) {
            return (dealer.naturapy || dealer.naturapypickup);
        },

        isPremium(dealer) {
            return (dealer.premium || dealer.propremium);
        },

        isClosed(dealer, weekday) {
            return (this.getOpeningHours(dealer, weekday, true) === '[closed]');
        },

        hasOpeningHours(dealer) {
            if (dealer) {
                return (
                    dealer.opening_hours_monday ||
                    dealer.opening_hours_tuesday ||
                    dealer.opening_hours_wednesday ||
                    dealer.opening_hours_thursday ||
                    dealer.opening_hours_friday ||
                    dealer.opening_hours_saturday ||
                    dealer.opening_hours_sunday ||
                    dealer.closing_days
                );
            } else {
                return false;
            }
        },

        getOpeningHours(dealer, weekday, raw = false) {
            let value = dealer[`opening_hours_${weekday}`];

            if (raw) {
                return value.toLowerCase();
            }

            if (value.toLowerCase() === '[closed]') {
                return this.$t('dealers.opening_hours_closed');
            }

            return value;
        },

        formattedPhone(phone) {
            return phone.replace(/[^0-9]/g, '');
        },

        formattedDate(date) {
            return new Date(date).toLocaleDateString(this.locale, this.dateOptions);
        },

        async doSearch(additionalParams = {}) {
            let endpoint = '/dealers/list';
            let params = {
                segment: this.segment,
                lat: this.locationCoords.lat,
                lng: this.locationCoords.lng,
                distance: this.locationDistance,
                country: JSON.parse(this.countries).join(','),
                limit: 10000
            };

            Object.assign(params, additionalParams);

            try {
                this.isLoading = true;

                const result = await this.api.client.invoke(`dealers post ${endpoint}`, params);
                this.dealers = result.data;
            } catch (error) {
                if (error.status !== 404) {
                    console.error(error);
                }

                this.dealers = [];
            }

            this.dealer = null;
            this.updateMarkers();

            this.isLoading = false;
        }
    }
}
