export default {
    data() {
        return {
            countries: [],
            salutations: []
        }
    },

    computed: {
        selectedCountry() {
            if (!this.countryId) return;

            return this.countries.find(country => country.id === this.countryId);
        }
    },

    methods: {
        async getCountries() {
            const result = await this.api.client.invoke("countries post /country", {});
            this.countries = result.elements;
        },

        async getSalutations() {
            const result = await this.api.client.invoke("salutations post /salutation", {});
            this.salutations = result.elements;
        },

        async updateCustomerProfile(data) {
            return await this.api.client.invoke("changeProfile post /account/change-profile", data);
        },

        async updateCustomerAddress(addressId, data, setDefault = null) {
            const result = await this.api.client.invoke("updateCustomerAddress patch /account/address/{addressId}", {
                addressId,
                ...data
            });

            if (setDefault && result.id) {
                await this.setDefaultAddress(result.id, setDefault);
            }

            return result;
        },

        async createCustomerAddress(customerId, data, setDefault = null) {
            const result = await this.api.client.invoke("createCustomerAddress post /account/address", {
                id: this.createUUID(),
                customerId,
                ...data
            });

            if (setDefault && result.id) {
                await this.setDefaultAddress(result.id, setDefault);
            }

            return result;
        },

        async setDefaultAddress(addressId, addressType) {
            const type = addressType.toLowerCase();
            const typeCaml = type.charAt(0).toUpperCase() + type.slice(1);

            const endpoint = `default${ typeCaml }Address patch /account/address/default-${ type }/{addressId}`;

            await this.api.client.invoke(endpoint, { addressId });
        },

        findShippingSpecificAddressId(customer) {
            if (!customer) {
                return null;
            }

            // check if the active shipping address is already specific
            if (customer.activeBillingAddress?.id !== customer.activeShippingAddress?.id) {
                return customer.activeShippingAddress?.id;
            }

            // check if different default shipping address is already defined
            if (customer.defaultBillingAddressId !== customer.defaultShippingAddressId && customer.defaultShippingAddressId !== customer.activeShippingAddress?.id) {
                return customer.defaultShippingAddressId;
            }

            // use the first available address for shipping, which is not the billing address
            if (customer.addresses.length > 1) {
                const address = customer.addresses.find((address) => address.id !== customer.activeShippingAddress.id);

                if (!address) {
                    return null;
                }

                return address.id;
            }

            return null;
        },

        createUUID() {
            return crypto.randomUUID().replaceAll('-', '');
        }
    }
}
