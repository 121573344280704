<template>
    <div>
        <div
            class="modal modal-lg fade modal-search-bar"
            id="searchModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="searchModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <ais-instant-search
                            :search-client="searchClient"
                            :index-name="firstIndexName"
                        >
                            <div class="search-wrapper">
                                <ph-magnifying-glass class="search-icon" />
                                <ais-search-box
                                    ref="searchBox"
                                    :placeholder="inputPlaceholder"
                                    show-loading-indicator
                                >
                                    <template
                                        v-slot="{
                                            currentRefinement,
                                            isSearchStalled,
                                            refine,
                                        }"
                                    >
                                        <form
                                            class="ais-SearchBox-form"
                                            novalidate
                                        >
                                            <input
                                                ref="searchInput"
                                                :placeholder="inputPlaceholder"
                                                :value="currentRefinement"
                                                @input="
                                                    (event) => {
                                                        refine(event.currentTarget.value);
                                                        searchQuery = event.currentTarget.value;
                                                    }
                                                "
                                                class="ais-SearchBox-input"
                                            />
                                            <span :hidden="!isSearchStalled">Loading...</span>
                                            <button
                                                v-if="currentRefinement"
                                                class="ais-SearchBox-reset"
                                                type="reset"
                                                title="Clear the search query."
                                                @click="clearInput"
                                            >
                                                <ph-eraser size="20" color="#2f2f2fb3" />
                                            </button>
                                        </form>
                                    </template>
                                </ais-search-box>
                                <button
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#searchModal"
                                    class="close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <ais-configure :hitsPerPage="hitsPerPage" />

                            <div class="contents flex-column">
                                <!-- Suggestions - Start -->
                                <div class="col-suggestions w-100">
                                    <div class="suggestions-area">
                                        <p class="column-title">
                                            {{ $t('search.suggestions_title') }}
                                        </p>

                                        <ul>
                                            <li
                                                v-for="suggestion in suggestions"
                                                :key="suggestion.objectID"
                                            >
                                                <a
                                                    href="#"
                                                    @click.prevent="applySuggestion(suggestion.query)"
                                                >
                                                    {{ suggestion.query }}
                                                </a>
                                            </li>
                                        </ul>

                                        <p class="alert alert-warning small m-0 mt-2">
                                            <b>Notice:</b> Only nl-be and fr-be indexes are available
                                        </p>
                                    </div>
                                </div>
                                <!-- Suggestions - End -->

                                <!-- Results - Start -->
                                <div class="col-results w-100" v-if="searchQuery">
                                    <div class="scroll-area">
                                        <div class="row g-4 gx-xl-6" :class="['row', 'g-4', 'gx-xl-6', { 'd-none': areIndexesEmpty() }]">
                                            <div
                                                v-for="index in indexes"
                                                :key="index.name"
                                                :class="['col-12', 'col-md-6', { 'd-none': !indexHasResults(index.name) }]"
                                            >
                                                <ais-index :index-name="index.name">
                                                    <ais-state-results>
                                                        <template v-slot="{ results }">
                                                            <div :register="registerEmptyIndex(index.name, results.hits.length)" v-if="results.query && results.hits.length > 0" >
                                                                <p class="column-title">
                                                                    {{ index.label }}
                                                                </p>
                                                                <ais-hits>
                                                                    <template v-slot="{ items }">
                                                                        <div v-for="item in items" :key="item.objectID">
                                                                            <a :href="item.url" :class="['hit-item', `type-${item.type}`]">
                                                                                <div class="cover-img">
                                                                                    <img v-if="item.cover" :src="item.cover" alt="Cover Image" />
                                                                                    <div v-if="!item.cover" class="empty"></div>
                                                                                </div>
                                                                                <p class="title">{{ item.title }}</p>
                                                                            </a>
                                                                        </div>

                                                                        <div class="text-center mt-2" v-if="items.length && items.length >= hitsPerPage">
                                                                            <a :href="getMoreUrl(items[0])" class="btn btn-sm btn-light" @click.prevent="handleButtonClick(getMoreUrl(items[0]))">
                                                                                {{ $t('search.show_more_label') }}
                                                                            </a>
                                                                        </div>
                                                                    </template>
                                                                </ais-hits>
                                                            </div>

                                                            <!-- This is not really used but I left it here as a fallback -->
                                                            <div :register="registerEmptyIndex(index.name, results.hits.length)" v-if="results.query && results.hits.length === 0" class="no-results">
                                                                <p>{{ $t('search.no_results') }}</p>
                                                            </div>
                                                        </template>
                                                    </ais-state-results>
                                                </ais-index>
                                            </div>
                                        </div>

                                        <div v-if="areIndexesEmpty()" class="text-center">
                                            <p>{{ $t('search.no_results') }}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Results - End -->
                            </div>
                        </ais-instant-search>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import algoliasearch from "algoliasearch/lite";
import {
    AisInstantSearch,
    AisSearchBox,
    AisIndex,
    AisHits,
    AisConfigure,
    AisStateResults,
} from "vue-instantsearch";
import { PhMagnifyingGlass, PhEraser } from "phosphor-vue";

export default {
    components: {
        AisInstantSearch,
        AisSearchBox,
        AisIndex,
        AisHits,
        AisConfigure,
        AisStateResults,
        PhMagnifyingGlass,
        PhEraser,
    },
    props: {
        site: String,
        division: String,
    },
    data() {
        return {
            searchClient: algoliasearch(
                import.meta.env.VITE_ALGOLIA_APP_ID,
                import.meta.env.VITE_ALGOLIA_SECRET
            ),
            context: this.division,
            locale: this.site,
            indexes: [],
            indexCounts: {},
            suggestions: [],
            searchQuery: "", // Track the search query
        };
    },
    computed: {
        firstIndexName() {
            return this.indexes.length > 0 ? this.indexes[0].name : "";
        },
        hitsPerPage() {
            return this.division === "hobby" ? 6 : 4;
        },
        inputPlaceholder() {
            return this.context === "hobby"
                ? this.$t('search.input_placeholder_hobby')
                : this.$t('search.input_placeholder_pro');
        },
        indexHasResults() {
            return (indexName) => {
                return !this.isIndexEmpty(indexName);
            };
        },
    },
    created() {
        this.indexes = this.getIndexes();
        this.fetchSuggestions();
    },
    mounted() {
        $("#searchModal").on("shown.bs.modal", () => {
            this.$refs.searchInput.focus();
        });
    },
    methods: {
        getIndexes() {
            const context = this.context;
            const locale = this.locale;

            const productIndexName = `${context}-products_${locale}`;
            const productLabel = this.$t('search.index_title_products');

            const additionalIndexes = {
                hobby: [
                    {
                        name: `garden_tips_${locale}`,
                        label: this.$t('search.index_title_blog'),
                    },
                ],
                pro: [
                    {
                        name: `innovations_${locale}`,
                        label: this.$t('search.index_title_innovations'),
                    },
                    {
                        name: `advices_${locale}`,
                        label: this.$t('search.index_title_advices'),
                    },
                    {
                        name: `expert_cases_${locale}`,
                        label: this.$t('search.index_title_expert_cases'),
                    },
                ],
            };

            return [
                { name: productIndexName, label: productLabel },
                ...additionalIndexes[context],
            ];
        },
        async fetchSuggestions() {
            const indexName = `${this.context}-query_suggestions_${this.locale}`;
            try {
                const index = this.searchClient.initIndex(indexName);
                const result = await index.search("", { hitsPerPage: 6 });
                this.suggestions = result.hits;
            } catch (error) {
                console.error("Error fetching suggestions:", error);
            }
        },
        applySuggestion(query) {
            const input = this.$refs.searchInput;
            input.value = query;
            input.dispatchEvent(new Event("input"));
        },
        clearInput(event) {
            event.preventDefault();
            this.$refs.searchInput.value = "";
            this.$refs.searchInput.dispatchEvent(new Event("input"));
            this.searchQuery = ""; // Clear searchQuery
        },
        getMoreUrl(item) {
            const input = this.$refs.searchInput;

            if (item.url) {
                const segments = item.url.split('/');
                segments.pop();

                if (item.type === 'products') {
                    segments.pop();
                }

                let moreUrl = segments.join('/');

                if (input.value) {
                    moreUrl += '?search=' + input.value + '#overview';
                }

                return moreUrl;
            }
            return '';
        },
        handleButtonClick(url) {
            if (window.location.href.endsWith(url)) {
                window.location.reload();
            } else {
                window.location.href = url;
            }
        },
        registerEmptyIndex(index, count) {
            // Store the index and its count in indexCounts object
            this.$set(this.indexCounts, index, count);
        },
        isIndexEmpty(index) {
            if (index in this.indexCounts) {
                return this.indexCounts[index] === 0;
            } else {
                return false;
            }
        },
        areIndexesEmpty() {
            for (let index in this.indexCounts) {
                if (!this.isIndexEmpty(index)) {
                    return false;
                }
            }
            return true;
        }
    },
};
</script>
